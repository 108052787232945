<template>
  <div class="emergency-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <div class="d-flex align-items-center">
          <h3 class="my-2 mr-4" style="font-size: 20px;">Emergency mode</h3>
          <div class="d-flex align-items-center">
            <a-switch class="mr-2" v-model="disabledStatus" @change="changeDisabledStatus" />
            <span class="emergency-content__status">
              {{ disabledStatus ? 'Active' : 'Disabled'}}
            </span>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-card>
      <div>
        <a-radio-group v-model="contentStatus">
          <a-radio-button :disabled="!disabledStatus" value="message">
            Message
          </a-radio-button>
          <a-radio-button :disabled="!disabledStatus" value="webview">
            Webview
          </a-radio-button>
        </a-radio-group>
      </div>
      <div v-if="disabledStatus" class="mt-3">
        <message
          v-if="contentStatus === 'message'"
          ref="message-component"
          :ruMessage="ruMessage"
          :enMessage="enMessage"
          :ruButtonName="ruButtonName"
          :enButtonName="enButtonName"
          :buttonLink="buttonLink"
          @changeRuValue="changeRuValue"
          @changeEnValue="changeEnValue"
          @changeRuButtonName="changeRuButtonName"
          @changeEnButtonName="changeEnButtonName"
          @changeButtonLink="changeButtonLink"
          @send="sendEmergencyData"
        />
        <webview
          v-else
          :webview="webview"
          @changeWebviewValue="changeWebviewValue"
          @send="sendEmergencyData"
        />
      </div>
      <div v-else class="emergency-content__no-empty">
        <img src="/resources/images/empty/no-empty.png" alt="No data">
        <span>No settings</span>
      </div>
      <div v-if="disabledStatus" class="emergency-content__footer">
        <a-button
          type="primary"
          :loading="loading"
          @click="sendEmergencyData"
        >
          Save
        </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

export default {
  name: 'index',
  components: {
    message: () => import('./components/message.vue'),
    webview: () => import('./components/webview.vue'),
  },
  mixins: [accessMix],
  data() {
    return {
      disabledStatus: false,
      contentStatus: 'message',
      simpleImage: null,
      webview: '',
      ruMessage: '',
      enMessage: '',
      ruButtonName: '',
      enButtonName: '',
      buttonLink: '',
      loading: false,
    }
  },
  methods: {
    changeDisabledStatus() {
      this.changeEmergencyStatus()
    },
    async sendEmergencyData() {
      this.loading = true
      const data = {
        status: this.disabledStatus,
      }

      if (this.disabledStatus) {
        if (this.contentStatus === 'message') {
          const requiredFields = await this.$refs['message-component'].checkFormValues()
          if (!requiredFields) {
            this.loading = false
            this.$notification.error({
              message: 'Completing required fields',
              description: '',
            })
            return
          }
          data.message = [
            {
              lang_id: 0,
              text: this.ruMessage,
            },
            {
              lang_id: 2,
              text: this.enMessage,
            },
          ]
          if (this.ruButtonName?.length || this.enButtonName?.length) {
            data.button_title = [
              { lang_id: 0, title: this.ruButtonName },
              { lang_id: 2, title: this.enButtonName },
            ]
          }
          data.button_link = this.buttonLink
          data.webview = null
        } else {
          data.message = null
          data.button_title = [
            { lang_id: 0, title: '' },
            { lang_id: 2, title: '' },
          ]
          data.button_link = ''
          data.webview = this.webview
        }
      }
      const url = '/admin/emergency'
      apiClient.post(url, data).then((response) => {
        this.$notification.success({
          message: 'Successful request',
          description: '',
        })
      }).catch(error => {
        this.$notification.error({
          message: 'Error',
          description: error.message,
        })
      }).finally(() => {
        this.loading = false
      })
    },
    changeEmergencyStatus() {
      this.loading = true
      const data = {
        status: this.disabledStatus,
      }
      const url = '/admin/emergency'
      apiClient.post(url, data).then((response) => {
        this.$notification.success({
          message: 'Successful change status',
          description: '',
        })
      }).catch(error => {
        this.$notification.error({
          message: 'Error',
          description: error.message,
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getEmergencyStatus() {
      const url = '/admin/emergency'
      apiClient.get(url).then((response) => {
        this.setEmergencyData(response.data.data)
      }).catch(error => {
        this.$notification.error({
          message: 'Error',
          description: error.message,
        })
      })
    },
    setEmergencyData(data) {
      if (data?.status) {
        this.disabledStatus = true
      }

      this.webview = data?.webview ? data.webview : ''
      this.ruMessage = data?.message ? data.message[0].text : ''
      this.enMessage = data?.message ? data.message[1].text : ''
      this.ruButtonName = data?.buttonTitle ? data.buttonTitle[0].title : ''
      this.enButtonName = data?.buttonTitle ? data.buttonTitle[1].title : ''
      this.buttonLink = data?.buttonLink ? data.buttonLink : ''

      if (this.webview) {
        this.contentStatus = 'webview'
      }
    },
    changeRuValue(value) {
      this.ruMessage = value
    },
    changeEnValue(value) {
      this.enMessage = value
    },
    changeRuButtonName(value) {
      this.ruButtonName = value
    },
    changeEnButtonName(value) {
      this.enButtonName = value
    },
    changeButtonLink(value) {
      this.buttonLink = value
    },
    changeWebviewValue(value) {
      this.webview = value
    },
  },
  mounted() {
    this.getEmergencyStatus()
  },
}
</script>

<style lang="scss">
@import '@/components/mixins.scss';

.emergency-content {
  &__status {
    color: $gray-7;
    font-weight: 400;
    font-size: 14px;
  }
  &__no-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    & img {
      width: 184px;
    }
    & span {
      display: inline-block;
      margin-top: 10px;
      color: $gray-7;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &__footer {
    margin: 24px -24px 0 -24px;
    display: flex;
    justify-content: flex-end;
    padding: 10px 24px 0 24px;
    border-top: 1px solid $gray-12;
  }
  & .ant-radio-button-wrapper-disabled {
    background-color: $gray-11 !important;
    border: 1px solid $gray-12 !important;
    & span {
      color: $gray-10 !important;
    }
  }
  & input {
    height: 40px !important;
    font-size: 16px !important;
  }
}
.emergency-tabs {
  &__head {
    color: $gray-9;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  &__desc {
    color: $gray-7;
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  & .ant-tabs-bar {
    margin-bottom: 0 !important;
  }
  &__textarea {
    // border: none !important;
    // border: 1px solid $gray-12 !important;
    border-top: none !important;
    border-left: 1px solid $gray-2 !important;
    border-right: 1px solid $gray-2 !important;
    border-bottom: 1px solid $gray-2 !important;
    border-radius: 0 0 2px 2px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
</style>
<style scoped>
.emergency-content .ant-card {
  flex: 0 1 auto;
}
</style>
